<template>
    <v-container>
      <div class="d-block d-md-flex">
        <AppAside></AppAside>
        <div class="ps-5 page_content grow">
          problem
        </div>
      </div>
    </v-container>
  </template>
  
  
  <script>
      export default {
          name:"problem"
      }
  </script>